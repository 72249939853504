import React from 'react';


import '../main-view/main-view.scss';
import './login-view.scss';

export default class LoginView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null
        }
    }
    
    componentDidMount() {
        //this.checkLoggedIn();
    }

    googleLogin() {

    }

    checkLoggedIn() {
        if (localStorage.getItem('user')) {
            alert('we got a user!')
            window.location.href = "/admin";
        } else {console.log("No current User")}
    }

    render() {
        return (
            <div className="login-view">
                <h1 className="login-header mx-auto">Please login to access the admin panel</h1>
                <button className="btn-PGP mx-auto" onClick={() => this.googleLogin()}>Login</button>
                <a className="btn-PGP center-button mx-auto" href="/">Return Home</a>
            </div>
        )}
}