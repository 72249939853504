import React from 'react';
import NavMenu from '../nav-menu/nav-menu';
import PageFooter from '../page-footer/page-footer';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';
import { emailJSID, serviceID, templateID } from '../../utilities.jsx';

import '../main-view/main-view.scss';
import "./quote-view.scss";

export default class QuoteView extends React.Component {
    constructor() {
        super();
        this.state = {
            name: "",
            senderEmail: "",
            projectDescription: "",
            finishedShooting: false,
            developmentStage: "",
            deadline: ""
        }
    }
    sendEmail() {
        let formFill = {
            name: this.state.name,
            senderEmail: this.state.senderEmail,
            projectDescription: this.state.projectDescription,
            finishedShooting: this.state.finishedShooting,
            developmentStage: this.state.developmentStage,
            deadline: this.state.deadline,
        }
        init(emailJSID);
        console.log(formFill);
        emailjs.send(serviceID, templateID, formFill)
            .then((result) => {
                console.log(result.text);
            }).catch(err => console.log(err.text));
    }

    render() {
        return(
            <div className="quote-view">
                <NavMenu />
                <h1 className="mx-auto header-text-center">How can we help?</h1>
                <p className="mx-auto body-centered">
                    We get it, these projects take a lot of work.
                    Tell us a little about your project. We'll be in touch
                    soon to talk about what we can do to help.
                </p>
                <Form className="quote-form mx-auto">
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="What should we call you?"
                            value={this.state.name} 
                            onChange={(e) => {this.setState({name: e.target.value})}}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter email"
                            value={this.state.senderEmail}
                            onChange={(e) => {this.setState({senderEmail: e.target.value})}}/>
                        <Form.Text style={{color: "#A5B9CC"}}>
                            Your Email will not be used for marketing purposes.
                        </Form.Text>
                    </Form.Group>
                    
                    <Form.Group>
                        <Form.Label>Please describe your project:</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows={5} 
                            value={this.state.projectDescription}
                            onChange={(e) => this.setState({projectDescription: e.target.value})}
                            />
                    </Form.Group>
                    
                    <Form.Group>
                        <Form.Check 
                        type="checkbox" 
                        label="We have finished shooting." 
                        checked={this.state.finishedShooting}
                        onChange={(e) => this.setState({finishedShooting: e.target.checked ? "Yes" : "No"})}
                        />
                    </Form.Group>
                    
                    <Form.Group>
                        <Form.Label>Our project is currently in...</Form.Label>
                        <Form.Control 
                            as="select"
                            onChange={(e) => {this.setState({developmentStage: e.target.value})}}
                            >
                            <option value="development">Development</option>
                            <option value="pre-production">Pre-production</option>
                            <option value="production">Production</option>
                            <option value="post-production">Post-production</option>
                            <option value="finishing touches">Finishing touches</option>
                            <option value="limbo. I don't really know...">I don't know...</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Projected deadline:</Form.Label>
                        <Form.Control 
                        type="date" 
                        value={this.state.deadline}
                        onChange={(e) => {this.setState({deadline: e.target.value})}}
                        />
                    </Form.Group>
                    <Button
                        className="quote-submit"
                        onClick={() => {this.sendEmail()}}
                    >Get My Quote!</Button>
                </Form>
                <PageFooter />
            </div>
        )
    }

}