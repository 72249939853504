import React from 'react';
import { Link } from 'react-router-dom';
import fullLogo from './fullLogo.png';

import '../main-view/main-view.scss';
import './page-footer.scss';

export default class PageFooter extends React.Component {
    render() {
        return (
            <div className="PageFooter">
                <div className="footer-column mx-auto">
                    <a href='/' className="footer-logo">
                        <img 
                            src={fullLogo} 
                            alt="Home" 
                            height="100px" 
                            width="auto"
                            style={{
                                marginTop: "25px"
                            }}
                        />
                    </a>
                </div>
                <div className="footer-column">
                    <Link to="/"><h2 className="grey">Home</h2></Link>
                    <Link to="/about-us"><h2 className="grey">About Us</h2></Link>
                    <Link to="/services"><h2 className="grey">Services</h2></Link>
                    <Link to="/portfolio"><h2 className="grey reveal-mobile">Our Work</h2></Link>
                </div>
                <div className="footer-column mx-auto portfolio-column">
                    <Link to="/portfolio"><h2 className="grey">Our Work</h2></Link>
                    <a className="body-text" href="/portfolio">We've done a LOT of work over the years. Check it out.</a>
                </div>
                <div className="footer-column mx-auto">
                    <h2 className="grey quote-header">Explore your project</h2>
                    <a className="btn-PGP mx-auto" href="/quote">Get a Quote</a>
                </div>
            </div>
        )
    }
}