import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import firebase from 'firebase';

import '../main-view/main-view.scss';

export default class PostEditor extends React.Component {
    constructor() {
        super();
        this.state= {
            editedPostData:{},
            showDeleteModal: false
        }
    }

    saveChanges() {
        if (this.props.currentPost.videoID) {
            const portfolioRef = firebase.firestore().collection('Portfolio').doc(this.props.currentPost.title);
            portfolioRef.set(
                this.state.editedPostData,
                { merge: true}
            ).then(() => {
                alert("Portfolio piece updated successfully!")
                window.location.href = '/admin';
            })
            .catch(err => { 
                console.log(err)
                alert("There was an error updating this portfolio piece. Please try again later.")
                window.location.href = '/admin';
            });
        } else {
            const blogRef = firebase.firestore().collection("BlogPosts").doc(this.props.currentPost.title);
            blogRef.set(
                this.state.editedPostData,
                { merge: true}
            ).then(() => {
                alert("In Development post updated successfully!")
                window.location.href = '/admin';
            })
            .catch(err => {
                console.log(err)
                alert("There was a problem saving this post. Please try again later.")
                window.location.href = '/admin';
            });
        }

    }

    deletePost() {
        if (this.props.currentPost.videoID) {
            const portfolioRef = firebase.firestore().collection('Portfolio').doc(this.props.currentPost.title);
            portfolioRef.delete()
            .then(() => {
                alert("Post successfully deleted!")
                window.location.href = '/admin';
            })
            .catch(err => {
                console.log(err);
                alert("There was an error deleting this portfolio piece. Please try again later.");
                window.location.href = '/admin';
            })
        } else {
            const blogRef = firebase.firestore().collection('BlogPosts').doc(this.props.currentPost.title);
            blogRef.delete()
            .then(() => {
                alert("In Development post successfully deleted!")
                window.location.href = '/admin';
            })
            .catch(err => {
                console.log(err);
                alert("There was a problem deleting this In Development post. Please try again later.")
                window.location.href = '/admin';
            })
        }
    }

    async deleteImage(image) {
        let j = -1;
        let newImages = [];
        for (let i = 0; i < this.props.currentPost.images.length; i++) {
            if (this.props.currentPost.images[i] === image) {
                j = i;
                newImages = this.props.currentPost.images;
                newImages.splice(j);
                i +=1;
            } else  {
                i += 1;
            }
        }
        try {
            let storage = firebase.storage()
            let imageRef = storage.refFromURL(image);
            console.log(imageRef);
            await imageRef.delete();
            const postRef = firebase.firestore().collection('BlogPosts').doc(this.props.currentPost.title);
            await postRef.update({images: newImages});
            document.getElementById(image).remove()
        } catch (err) {
            console.log(err);
            alert('There was an issue deleting this image.');
        }
    }


    render() {
        
        return (
            <div>
            <Modal
                show={this.props.show}
                centered
                >
                    <Modal.Header>
                        Edit Post
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <h2>
                                {this.props.currentPost.title}
                            </h2>
                            <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control 
                                    type="text"
                                    placeholder={this.props.currentPost.description}
                                    value={this.state.editedPostData.description}
                                    onChange={(e) => this.setState({editedPostData: {description: e.target.value}})}
                                />
                            </Form.Group>
                            {this.props.currentPost.videoID ? (
                                <Form.Group>
                                    <Form.Label>Video ID</Form.Label>
                                    <Form.Text>This is the vimeo video that will play. Make sure it is formatted correctly on Vimeo.com.</Form.Text>
                                    <Form.Control 
                                        type="text"
                                        placeholder={this.props.currentPost.videoID}
                                        value={this.state.editedPostData.videoID}
                                        onChange={(e) => this.setState({editedPostData: {videoID: e.target.value}})}
                                    />
                                </Form.Group>
                            ) : null}
                            <Button onClick={() => this.saveChanges()}>Save Changes</Button>
                            {this.props.currentPost.images ? (
                                <div>
                                    Images with this post: <br/>
                                    Note: Clicking on the delete image button in irreversible!
                                    <div className="d-flex">
                                    {this.props.currentPost.images.map(image => {
                                            return (
                                        <div className="d-flex flex-column mx-auto " id={image}>
                                            <img src={image} alt="" height="100px"/>
                                            <Button onClick={() => this.deleteImage(image)}>Delete Image</Button>
                                        </div>
                                        )
                                    })}</div>
                                </div>
                            ) : null}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => window.location.href = "/admin"}>Cancel</Button>
                        <Button variant="danger" onClick={() => this.setState({showDeleteModal: true})}>Delete Post</Button>
                    </Modal.Footer>
            </Modal>
            <Modal
                show={this.state.showDeleteModal}
                centered
            >
                <Modal.Header>
                    Are You Sure?
                </Modal.Header>
                <Modal.Body>
                    <h2>This action cannot be undone. Are you sure you want to delete this post?</h2>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.setState({showDeleteModal: false})}>Cancel</Button>
                    <Button onClick={() => this.deletePost()}>Delete</Button>
                </Modal.Footer>
            </Modal>
            </div>
        )
    }
}