import React from 'react';
import Nav from 'react-bootstrap/Nav';
import NavBar from 'react-bootstrap/NavBar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import logo from '../../logo.png';
import firebase from 'firebase';

import '../main-view/main-view.scss';
import "./nav-menu.scss";

export default class NavMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logoutButton: false
        }
    }

    componentDidMount() {
        if (this.props.logout) this.setState({logoutButton: true})
    }

    logoutButton() {
        if (this.state.logoutButton) {
            return (
                <Nav.Item>
                    <Button onClick={() => {
                        firebase.auth().signOut();
                        window.location.href = "/";
                    }} >Logout</Button>
                </Nav.Item>
            );
        } else return null;
    }

    render() {

        return(
            <div className="NavMenu">
                <NavBar bg="dark" variant="dark" expand="md" sticky="top">
                    <NavBar.Brand href="/">
                        <img 
                            src={logo}
                            alt="Parking Garage Pictures"
                            height="50px"
                            width="50px"
                        />
                    </NavBar.Brand>
                    <NavBar.Toggle aria-controls="basic-navbar-nav" />
                    <NavBar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/about-us">About Us</Nav.Link>
                            <Nav.Link href="/in-development">In Development</Nav.Link>
                            <Nav.Link href="/portfolio">Portfolio</Nav.Link>
                            <NavDropdown title="Services" bg="dark" variant="dark">
                                <NavDropdown.Item href="/services">All Services</NavDropdown.Item>
                                <NavDropdown.Item href="/vfx">VFX</NavDropdown.Item>
                                <NavDropdown.Item href="/motion-graphics">Motion Graphics</NavDropdown.Item>
                                <NavDropdown.Item href="/video-editing">Video Editing</NavDropdown.Item>
                                <NavDropdown.Item href="/fix-it-in-post">Post Production</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </NavBar.Collapse>

                    <Nav>
                        {this.logoutButton()}
                        <Nav.Link href="/quote">Get a Quote</Nav.Link>
                    </Nav>
                </NavBar>
            </div>
        )
    }

}