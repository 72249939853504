import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import NavMenu from '../nav-menu/nav-menu';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import DropZone from 'react-dropzone';
import firebase from 'firebase';
import "firebase/auth";

import '../main-view/main-view.scss';
import "./admin-view.scss";
import PageFooter from '../page-footer/page-footer';
import PostEditor from '../post-edit/post-edit';
import { Form } from 'react-bootstrap';

export default class AdminView extends React.Component {
    constructor() {
        super();
        this.state = {
            home: {},
            about: {},
            services: {},
            vfx: {},
            motionGraphics: {},
            videoEditing: {},
            postProduction: {},
            portfolio: {},
            user: {},
            showLogin: false,
            currentPost: {},
            portfolioTitle: '',
            portfolioDescription: '',
            portfolioVideoID: '',
            blogTitle: null,
            blogDescription: null,
            blogImages: [],
            showEdit: false,
            loading: false
        }
    }

    generatePostList(list) {
        let postList = [];
        for (const [key, data] of Object.entries(list)) {
            postList.push(
                <div className="short-post">
                    <h2 className="h2">{data.title}</h2>
                    <p className="body-text">{data.description}</p>
                    {data.videoID ? 
                    (<p className="body-text">{`Vimeo Video ID: ${data.videoID || "none"}`}</p>)
                    : null }
                    <Button onClick={() => {
                        console.log("EDIT");
                        this.setState({
                            currentPost: {
                                title: data.title,
                                description: data.description,
                                videoID: data.videoID,
                                images: data.images
                            },
                            showEdit: true,
                        })
                    }}>Edit Post</Button>
                </div>
            )
        }
        return postList;
    }

    editPost(data) {
        this.setState({
            name: data.name,
            description: data.description,
            videoID: data.videoID,
        });
    }

    componentDidMount() {
        this.checkLogin();
    }

    changePageData(page,field,data) {
        switch (page) {
            case 'home': {
                switch (field) {
                    case 'slogan': {
                        this.setState({home: {slogan: data}}); 
                        break;
                    }
                    case 'description': {
                        this.setState({home: {description: data}}); 
                        break;
                    }
                    case 'videoID': {
                        this.setState({home: {videoID: data}});
                        break;
                    }
                    default: break;
                } break;
            }
            case 'about': {
                switch (field) {
                    case 'description': {
                        this.setState({about: {description: data}});
                        break;
                    }
                    case 'joelBio': {
                        this.setState({about: {joelBio: data}});
                        break;
                    }
                    case 'ryanBio': {
                        this.setState({about: {ryanBio: data}});
                        break;
                    }
                    case 'teamDescription': {
                        this.setState({about: {teamDescription: data}});
                        break;
                    } default: break;
                } break;
            }
            case 'services': {
                switch (field) {
                    case 'description': {
                        this.setState({services: {description: data}});
                        break;
                    }
                    case 'videoID': {
                        this.setState({services: {videoID: data}});
                        break;
                    }
                    default: break;
                } break;
            }
            case 'vfx': {
                switch (field) {
                    case 'description': {
                        this.setState({vfx: {description: data}});
                        break;
                    }
                    case 'videoID': {
                        this.setState({vfx: {videoID: data}});
                        break;
                    }
                    default: break;
                } break;
            }
            case 'motionGraphics': {
                switch (field) {
                    case 'description': {
                        this.setState({motionGraphics: {description: data}});
                        break;
                    }
                    case 'videoID': {
                        this.setState({motionGraphics: {videoID: data}});
                        break;
                    }
                    default: break;
                } break;
            }
            case 'videoEditing': {
                switch (field) {
                    case 'description': {
                        this.setState({videoEditing: {description: data}});
                        break;
                    }
                    case 'videoID': {
                        this.setState({videoEditing: {videoID: data}});
                        break;
                    } default: break;
                }break;
            }
            case 'postProduction': {
                switch (field) {
                    case 'description': {
                        this.setState({postProduction: {description: data}});
                        break;
                    }
                    case 'videoID': {
                        this.setState({postProduction: {videoID: data}});
                        break;
                    }
                    default: break;
                } break;
            }
            default: {
                console.log('unable to log data');
                break;
            }
        }
    }

    
    savePageData(page, field, data) {
    const pageRef = firebase.firestore().collection('PageData').doc(page);
    switch (field) {
        case 'slogan': {
            pageRef.set({
            slogan: data
            }, { merge: true})
            .then(() => alert('Slogan was successfully updated!'))
            .catch((error) => {
                alert('There was a problem saving the new slogan. Please try again later.');
                console.log(error);
            });
            break;
        }

        case 'description':{
        console.log('setting description');  
        pageRef.set({
              description: data
          }, {merge: true})
          .then(() => alert ('Description successfully updated!'))
          .catch((e) => {
              alert('There was a problem saving the description. Please try again later.');
              console.log(e);
          })
          break;
        }

      case 'videoID':
          pageRef.set({
              videoID: data
          }, {merge: true})
          .then(() => alert ('Video ID successfully updated!'))
          .catch((e) => {
              alert('There was a problem saving the Video ID. Please try again later.');
              console.log(e);
          })
          break;
      
      case 'joelBio':
          pageRef.set({
              joelBio: data
          }, {merge: true})
          .then(() => alert ("Joel's Bio was successfully updated!"))
          .catch((e) => {
              alert("There was a problem saving Joel's Bio. Please try again later.");
              console.log(e);
          })
          break;

      case 'ryanBio':
          pageRef.set({
              ryanBio: data
          }, {merge: true})
          .then(() => alert ("Ryan's bio was successfully updated!"))
          .catch((e) => {
              alert("There was a problem saving Ryan's Bio. Please try again later.");
              console.log(e);
          })
          break;
      
  
      case 'teamDescription':
          pageRef.set({
              teamDescription: data
          }, {merge: true})
          .then(() => alert ('Team Bio successfully updated!'))
          .catch((e) => {
              alert('There was a problem saving Team Bio. Please try again later.');
              console.log(e);
          })
          break;
        
      default :
          alert('There was an error in processing. Please try again.')
  }
    }

    handleBlog(field, text) {
        switch (field) {
            case 'title': {
                this.setState({blogTitle: text});
                break;
            }
            case 'description': {
                this.setState({blogDescription: text});
                break;
            }
            default: break;
        }
    }

    onDropImage = (acceptedFiles) => {
        let blogImages = this.state.blogImages;
        blogImages.push(acceptedFiles[0]);
        this.setState({
            blogImages: blogImages
        });
    }

    loadingBar(value) { this.setState({loading: value})}

    newBlog() {
        if (this.state.blogTitle === '') {
            alert("Please enter a valid name");
            return null;
        } else {
            this.setState({loading: true});
            var imageURLs = [];
            this.state.blogImages.forEach(imageFile => {
                var storageRef = firebase.storage().ref("blogPosts/" + imageFile.name)
                storageRef.put(imageFile)
                .then(() => {
                    storageRef.getDownloadURL()
                    .then((url) => {
                        imageURLs.push(url);
                    })
                    .then(() => {
                        var blogRef = firebase.firestore().collection("BlogPosts");
                        let newPost = {
                            title: this.state.blogTitle,
                            description: this.state.blogDescription,
                            date: Date(),
                            images: imageURLs
                        };
                        blogRef.doc(this.state.blogTitle).set(newPost)
                        .then(() => {
                            console.log('image uploaded: ' + imageFile.name)
                            this.loadingBar(false);
                            alert("In Development post saved")
                        })
                        .catch(err => {
                            this.loadingBar(false);
                            alert('There was an issue saving the new post. Please try again later.');
                            console.log(err);
                        });
                    })
                })
                .catch(err => {
                    this.loadingBar(false);
                    console.log(err);
                    alert("There was an error uploading image" + imageFile.name)
                })
            })
        }
    }

    handlePortfolio(field, text) {
        switch (field) {
            case 'title': {
                this.setState({portfolioTitle: text});
                break;
            }
            case 'description': {
                this.setState({portfolioDescription: text});
                break;
            }
            case 'videoID': {
                this.setState({portfolioVideoID: text})
                break;
            }
            default: break;
        }
    }

    newPortfolio() {
        if (this.state.portfolioTitle === '') {
            alert("Please enter a valid name");
            return null;
        } else if (this.state.portfolioVideoID === '') {
            alert("Please enter a valid VideoID");
            return null;
        } else {
            var portfolioRef = firebase.firestore().collection("Portfolio");
            let newPortfolio = {
                title: this.state.portfolioTitle,
                description: this.state.portfolioDescription,
                videoID: this.state.portfolioVideoID,
                date: Date()
            };
            portfolioRef.doc(this.state.portfolioTitle).set(newPortfolio)
            .then(() => alert('Portfolio piece saved!'))
            .catch(err => {
                alert('There was an issue saving the new Portfolio post. Please try again later.');
                console.log(err);
            });
        }
    }

    checkLogin() {
        this.loadingBar(true);
        firebase.auth().onAuthStateChanged((user) => {
            if (!user) {
                this.loadingBar(false);
                this.setState({showLogin: true});
            } else {
                firebase.auth().getRedirectResult()
                .then((result) => {
                    var user = result.user;
                    this.setState({
                        showLogin: false
                    });
                    localStorage.setItem('user', user);
                    this.loadingBar(false);
                })
                .catch((err) => {
                    alert("Login Failed. Redirecting to Home");
                    window.location.href = "/";
                });
            }
        })
    }

    googleLogin() {
        var provider = new firebase.auth.GoogleAuthProvider();

        firebase.auth().onAuthStateChanged((user) => {
            console.log("checking for user.");
            if (!user) {
                        //Login with Google ID with Redirect
                firebase.auth().signInWithRedirect(provider)
            } 
            firebase.auth().getRedirectResult()
            .then((result) => {
                var user = result.user;
                this.setState({
                    user: user,
                    showLogin: false
                });
                localStorage.setItem('user', user);
            })
            .catch((err) => {
                alert("Login failed. Redirecting to Parking Garage Pictures Home");
                console.error(err);
                window.location.href = "/";
            });
        });
    }


    render() {
        return(
            <div className="admin-view">
                <Modal 
                    show={this.state.showLogin} 
                    centered
                    backdrop="static"
                    keyboard={false}
                    >
                    <Modal.Header>
                        <Modal.Title>Please Log In</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        This is the admin panel for Parking Garage Pictures and is intended for authorized personel only. Please login. If you arrived here in error, please press back to return to Parking Garage Pictures Home.
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn-PGP mx-auto" onClick={() => this.googleLogin()}>Login</button>
                        <button className="btn-PGP mx-auto" onClick={() => this.onLogout()}>Back</button>
                    </Modal.Footer>
                </Modal>

                <PostEditor currentPost={this.state.currentPost} show={this.state.showEdit} />

                <Modal 
                    show={this.state.loading} 
                    centered 
                    backdrop="static" 
                    keyboard={false}
                    className="d-flex"
                    >
                    <Modal.Body>
                        <Spinner className="text-center" animation="border" />
                    </Modal.Body>
                </Modal>
                
                <NavMenu logout={true} />
                <h1 className="mx-auto" style={{marginTop: "75px"}}> PARKING<span style={{color: "#F15B2A"}}>GARAGE</span>PICTURES</h1>
                <h1 className="mx-auto">Admin Panel</h1>
                <Tabs style={{marginBottom: "50px"}}>
                    <Tab eventKey="start" title="Start Here" className="tab mx-auto">
                        <h2 className="h2">Welcome!</h2>
                        <p className="body-text">
                            Welcome to your admin panel. Most of the things to change here should be pretty self
                            explanatory. However, there are some things to keep in mind when working here:
                        </p>
                        <h2 className="h2">1) Videos can be finicky.</h2>
                        <p className="body-text">
                            When getting ready to put a video up here, 
                            keep the following things in mind when working to get it
                            ready:<br/><br/>
                            - Make sure you have set the video to public access on Vimeo.com.
                            If you don't, everything will show up fine when you look at your website,
                            but no one else will be able to see the videos.<br/><br/>
                            - Get the video set up to display. In the screen where you can manage the video, there is an advanced button
                            on the right. If you click it, it will take you to another screen. This time,
                            click on the "Embed" dropdown on the left. When you do, there will be another
                            dropdown that can save a preset to the video. Set this to "Website Reel" to optimize
                            the video for your website.<br/><br/>
                        </p>
                        <h2 className="h2">2) Images are harder to change.</h2>
                        <p className="body-text">
                            There is currently no way for you to upload an image to the website
                            through this panel. If you have an image you want uploaded to the site, set it to
                            Jason.Lee.Cultrans@gmail.com with instructions as to where it goes and I'll
                            get it there within a day.
                        </p>
                        <h2 className="h2">3) Feel free to ask questions.</h2>
                        <p className="body-text">
                            If you have questions about how to upload something or if something isn't
                            working right, feel free to shoot me, Jason Lee, an email at Jason.Lee.Cultrans@gmail.com.
                            If it's an urgent issue, I can be reached at 385.209.4204.
                        </p>
                    </Tab>

                    <Tab eventKey="home-page" title="Home Page" className="tab mx-auto">
                        <Form>
                            <Form.Group>
                                <Form.Label className="h2">Background Video</Form.Label>
                                <Form.Text className="body-text">
                                    This is the main video that auto plays on the background of your home page. 
                                    When choosing a video for the background video, the best videos will be ones 
                                    without any intro or outro and a consistant aspect ratio throughout.<br/><br/>
                                    IMPORTANT! The video must be set to public to display properly. It must also be
                                    set to the "Background Video" preset in the Embed tab in its settings on Vimeo.com.
                                </Form.Text>
                                <Form.Text>

                                </Form.Text>
                                <Form.Control 
                                    type="text" 
                                    placeholder={this.props.home.videoID}
                                    value={this.state.home.videoID}
                                    onChange={e => this.changePageData('home', 'videoID', e.target.value)}
                                />
                                <Button 
                                    className="btn-save btn-PGP" 
                                    style={{marginTop: "25px"}}
                                    onClick={() => {this.savePageData('home', 'videoID', this.state.home.videoID)}}>
                                        Save new background video ID</Button>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="h2">Slogan</Form.Label>
                                <Form.Text className="body-text">
                                    The short slogan that appears under "PARKING GARAGE PICTURES" on top of the background video
                                </Form.Text>
                                <Form.Control 
                                    type="text" 
                                    placeholder={this.props.home.slogan}
                                    value={this.state.home.slogan}
                                    onChange={e => this.changePageData('home', 'slogan', e.target.value)}
                                />
                                <Button
                                    className="btn-save btn-PGP" 
                                    style={{marginTop: "25px"}}
                                    onClick={() => this.savePageData('home', 'slogan', this.state.home.slogan)}>
                                        Save new slogan
                                    </Button>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="h2">Home Page Blurb</Form.Label>
                                <Form.Text className="body-text">
                                    This is the description of PGP below the main video and logo.
                                </Form.Text>
                                <Form.Control 
                                    as="textarea" 
                                    rows={4} 
                                    placeholder={this.props.home.description} 
                                    value={this.state.home.description}
                                    onChange={e => this.changePageData('home', 'description', e.target.value)}
                                />
                                <Button
                                    className="btn-save btn-PGP" 
                                    style={{marginTop: "25px"}}
                                    onClick={() => this.savePageData('home', 'description', this.state.home.description)}>
                                        Save new Home Page Description
                                    </Button>
                            </Form.Group>
                        </Form>
                    </Tab>

                    <Tab eventKey="about" title="About Page" className="tab mx-auto">
                        <Form>
                            <Form.Group>
                                <Form.Label className="h2">Main Description</Form.Label>
                                <Form.Text className="body-text">The main description that sits at the top of the Parking Garage Pictures about page. This is before the bios. It sits underneath a big PARKING GARAGE PICTURES.</Form.Text>
                                <Form.Control 
                                    as="textarea" 
                                    rows={4} 
                                    placeholder={this.props.about.description} 
                                    value={this.state.about.description} 
                                    onChange={e => this.changePageData('about', 'description', e.target.value)}
                                />
                                <Button 
                                    className="btn-save btn-PGP"  
                                    style={{marginTop: "25px"}}
                                    onClick={() => {this.savePageData('about', 'description', this.state.about.description)}}>
                                        Save new description</Button>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="h2">Ryan's Bio</Form.Label>
                                <Form.Text className="body-text">The bio beneath Ryan's headshot</Form.Text>
                                <Form.Control 
                                    as="textarea" 
                                    rows={4} 
                                    placeholder={this.props.about.ryanBio} 
                                    value={this.state.about.ryanBio}
                                    onChange={e => this.changePageData('about', 'ryanBio', e.target.value)}
                                />
                                <Button 
                                    className="btn-save btn-PGP"  
                                    style={{marginTop: "25px"}}
                                    onClick={() => {this.savePageData('about', 'ryanBio', this.state.about.ryanBio)}}>
                                        Save new bio for Ryan</Button>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="h2">Joel's Bio</Form.Label>
                                <Form.Text className="body-text">The bio beneath Joel's headshot</Form.Text>
                                <Form.Control 
                                    as="textarea" 
                                    rows={4} 
                                    placeholder={this.props.about.joelBio} 
                                    value={this.state.about.joelBio}
                                    onChange={e => this.changePageData('about', 'joelBio', e.target.value)}
                                />
                                <Button 
                                    className="btn-save btn-PGP"  
                                    style={{marginTop: "25px"}}
                                    onClick={() => {this.savePageData('about', 'joelBio', this.state.about.joelBio)}}>
                                        Save new bio for Joel</Button>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="h2">Team Bio</Form.Label>
                                <Form.Text className="body-text">The description of your team. Remember that this should portray the flexibility of your team and your ability to scale to fit any project</Form.Text>
                                <Form.Control 
                                    as="textarea" 
                                    rows={4} 
                                    placeholder={this.props.about.teamDescription} 
                                    value={this.state.about.teamDescription}
                                    onChange={e => this.changePageData('about', 'teamDescription', e.target.value)}
                                />
                                <Button 
                                    className="btn-save btn-PGP"  
                                    style={{marginTop: "25px"}}
                                    onClick={() => {this.savePageData('about', 'teamDescription', this.state.about.teamDescription)}}>
                                        Save new bio for the PGP Team</Button>
                            </Form.Group>
                        </Form>
                    </Tab>

                    <Tab eventKey="development" title="In Development" className="tab mx-auto">
                        <Form>
                            <Form.Group>
                                <Form.Label className="h2">Edit In Development Post</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Title here" 
                                    value={this.state.blogTitle}
                                    onChange={e => {this.handleBlog('title', e.target.value)}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="h2">Comments</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    rows={3} 
                                    placeholder="Comments or text about the current project" 
                                    value={this.state.blogDescription}
                                    onChange={e => {this.handleBlog('description', e.target.value)}}
                                />
                            </Form.Group>
                            <DropZone 
                                onDrop={this.onDropImage} 
                                accept="image/png, image/gif, image/jpeg"
                                multiple
                            >
                                {({getRootProps, getInputProps, isDragActive}) => (
                                    <div {...getRootProps()} className="image-upload text-center">
                                        <input {...getInputProps()} />
                                        {isDragActive ? "Drop the image here to upload it" : "Click here to upload an image or Drag an image here to upload it"}
                                    </div>
                                )}
                            </DropZone>
                            {this.state.blogImages[0] ? (
                                <div className="body-text">Images: 
                                    {this.state.blogImages.map(image => (
                                        <div className="body-text">{image.name}</div>
                                    ))}
                                </div>
                            ) : null
                            } 
                            
                            <Button
                                    className="btn-save btn-PGP"
                                    style={{marginTop: "25px"}}
                                    onClick={() => this.newBlog()}>
                                        Save Post
                                    </Button>
                        </Form>
                        <h1 style={{marginTop: "75px"}}>Old posts</h1>
                        <div className="old-post-grid">
                            {this.generatePostList(this.props.blogPosts)}
                        </div>

                    </Tab>

                    <Tab eventKey="portfolio" title="Portfolio" className="tab mx-auto">
                        <Form>
                            <Form.Group>
                                <Form.Label className="h2">Edit Portfolio Post</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Title here" 
                                    value={this.state.currentPost.name}
                                    onChange={e => {this.handlePortfolio('title', e.target.value)}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="h2">Comments</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    rows={3} 
                                    placeholder="Comments or text about the current project" 
                                    value={this.state.currentPost.description}
                                    onChange={(e) => {this.handlePortfolio('description', e.target.value)}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="h2">Vimeo Video ID number</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="vimeo ID" 
                                    value={this.state.currentPost.videoID}
                                    onChange={e => {this.handlePortfolio('videoID', e.target.value)}}
                                />
                                <Button
                                    className="btn-save btn-PGP" 
                                    style={{marginTop: "25px"}}
                                    onClick={() => this.newPortfolio()}>
                                        Save Post
                                    </Button>
                            </Form.Group>
                        </Form>
                        <h1 style={{marginTop: "75px"}}>Other Portfolio Pieces</h1>
                        <div className="old-post-grid">
                            {this.generatePostList(this.props.portfolio)}
                        </div>
                    </Tab>

                    <Tab eventKey="services" title="All Services" className="tab mx-auto">
                        <Form>
                            <Form.Group>
                                <Form.Label className="h2">Full Services Reel</Form.Label>
                                <Form.Text className="body-text">
                                    This is your full services reel. Make sure it has a good intro and outro and shows everything.
                                </Form.Text>
                                <Form.Control 
                                    type="text" 
                                    placeholder={this.props.services.videoID}
                                    value={this.state.services.videoID}
                                    onChange={e => this.changePageData('services', 'videoID', e.target.value)}
                                />
                                <Button
                                    className="btn-save btn-PGP" 
                                    style={{marginTop: "25px"}}
                                    onClick={() => this.savePageData('services', 'videoID', this.state.services.videoID)}>
                                        Save new video ID for all services
                                    </Button>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="h2">Full Services Blurb</Form.Label>
                                <Form.Text className="body-text">The caption underneath your video</Form.Text>
                                <Form.Control 
                                    as="textarea" 
                                    rows={4} 
                                    placeholder={this.props.services.description}
                                    value={this.state.services.description}
                                    onChange={e => this.changePageData('services', 'description', e.target.value)}
                                />
                                <Button
                                    className="btn-save btn-PGP" 
                                    style={{marginTop: "25px"}}
                                    onClick={() => this.savePageData('services', 'description', this.state.services.description)}>
                                        Save new blurb
                                    </Button>
                            </Form.Group>
                        </Form>
                    </Tab>

                    <Tab eventKey="vfx" title="VFX" className="tab mx-auto">
                        <Form>
                            <Form.Group>
                                <Form.Label className="h2">VFX Reel</Form.Label>
                                <Form.Text className="body-text">
                                    This is your VFX reel. Make sure it shows off them good visuals.
                                </Form.Text>
                                <Form.Control 
                                    type="text" 
                                    placeholder={this.props.vfx.videoID}
                                    value={this.state.vfx.videoID}
                                    onChange={e => this.changePageData('vfx', 'videoID', e.target.value)}
                                />
                                <Button
                                    className="btn-save btn-PGP" 
                                    style={{marginTop: "25px"}}
                                    onClick={() => this.savePageData('vfx', 'videoID', this.state.vfx.videoID)}>
                                        Save new video
                                    </Button>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="h2">VFX Blurb</Form.Label>
                                <Form.Text className="body-text">The caption underneath your video</Form.Text>
                                <Form.Control 
                                    as="textarea" 
                                    rows={4}                                     
                                    placeholder={this.props.vfx.description}
                                    value={this.state.vfx.description}
                                    onChange={e => this.changePageData('vfx', 'description', e.target.value)}
                                />
                                <Button
                                    className="btn-save btn-PGP" 
                                    style={{marginTop: "25px"}}
                                    onClick={() => this.savePageData('vfx', 'description', this.state.vfx.description)}>
                                        Save new blurb
                                    </Button>
                            </Form.Group>
                        </Form>
                    </Tab>

                    <Tab eventKey="mograph" title="Motion Graphics" className="tab mx-auto">
                        <Form>
                            <Form.Group>
                                <Form.Label className="h2">Motion Graphics Reel</Form.Label>
                                <Form.Text className="body-text">
                                    This is your Motion Graphics reel. Only the prettiest cartoons please.
                                </Form.Text>
                                <Form.Control 
                                    type="text" 
                                    placeholder={this.props.motionGraphics.videoID}
                                    value={this.state.motionGraphics.videoID}
                                    onChange={e => this.changePageData('motionGraphics', 'videoID', e.target.value)}
                                />
                                <Button
                                    className="btn-save btn-PGP" 
                                    style={{marginTop: "25px"}}
                                    onClick={() => this.savePageData('motionGraphics', 'videoID', this.state.motionGraphics.videoID)}>
                                        Save new motion graphics video
                                    </Button>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="h2">Motion Graphics Blurb</Form.Label>
                                <Form.Text className="body-text">The caption underneath your video</Form.Text>
                                <Form.Control 
                                    as="textarea" 
                                    rows={4}
                                    placeholder={this.props.motionGraphics.description}
                                    value={this.state.motionGraphics.description}
                                    onChange={e => this.changePageData('motionGraphics', 'description', e.target.value)}
                                />
                                <Button
                                    className="btn-save btn-PGP"
                                    style={{marginTop: "25px"}}
                                    onClick={() => this.savePageData('motionGraphics', 'description', this.state.motionGraphics.description)}>
                                        Save new blurb
                                    </Button>
                            </Form.Group>
                        </Form>
                    </Tab>

                    <Tab eventKey="vid-edit" title="Video Editing" className="tab mx-auto">
                        <Form>
                            <Form.Group>
                                <Form.Label className="h2">Video Editing Reel</Form.Label>
                                <Form.Text className="body-text">
                                    This is your Video Editing reel. No mistakes on this one.
                                </Form.Text>
                                <Form.Control 
                                    type="text" 
                                    placeholder={this.props.videoEditing.videoID}
                                    value={this.state.videoEditing.videoID}
                                    onChange={e => this.changePageData('videoEditing', 'description', e.target.value)}
                                />
                                <Button
                                    className="btn-save btn-PGP" 
                                    style={{marginTop: "25px"}}
                                    onClick={() => this.savePageData('videoEditing', 'videoID', this.state.videoEditing.videoID)}>
                                        Save new Video Editing reel
                                    </Button>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="h2">Video Editing Blurb</Form.Label>
                                <Form.Text className="body-text">The caption underneath your video</Form.Text>
                                <Form.Control 
                                    as="textarea" 
                                    rows={4}
                                    placeholder={this.props.videoEditing.description}
                                    value={this.state.videoEditing.description}
                                    onChange={e => this.changePageData('videoEditing', 'description', e.target.value)}
                                />
                                <Button
                                    className="btn-save btn-PGP" 
                                    style={{marginTop: "25px"}}
                                    onClick={() => this.savePageData('videoEditing', 'description', this.state.videoEditing.description)}>
                                        Save new blurb
                                    </Button>
                            </Form.Group>
                        </Form>
                    </Tab>

                    <Tab eventKey="post" title="Post Production" className="tab mx-auto">
                        <Form>
                            <Form.Group>
                                <Form.Label className="h2">Post Production Reel</Form.Label>
                                <Form.Text className="body-text">
                                    This is your post production reel. Show them you can fix it in post.
                                </Form.Text>
                                <Form.Control 
                                    type="text" 
                                    placeholder={this.props.postProduction.videoID}
                                    value={this.state.postProduction.videoID}
                                    onChange={e => this.changePageData('postProduction', 'videoID', e.target.value)}
                                />
                                <Button
                                    className="btn-save btn-PGP" 
                                    style={{marginTop: "25px"}}
                                    onClick={() => this.savePageData('postProduction', 'videoID', this.state.postProduction.videoID)}>
                                        Save new Post Production video reel
                                    </Button>
                                    </Form.Group>
                            <Form.Group>
                                <Form.Label className="h2">Post Production Blurb</Form.Label>
                                <Form.Text className="body-text">The caption underneath your video</Form.Text>
                                <Form.Control 
                                    as="textarea" 
                                    rows={4}
                                    placeholder={this.props.postProduction.description}
                                    value={this.state.postProduction.description}
                                    onChange={e => this.changePageData('postProduction', 'description', e.target.value)}
                                />
                                <Button
                                    className="btn-save btn-PGP" 
                                    style={{marginTop: "25px"}}
                                    onClick={() => this.savePageData('postProduction', 'description', this.state.postProduction.description)}>
                                        Save new blurb
                                    </Button>
                            </Form.Group>
                        </Form>
                    </Tab>

                </Tabs>
                <PageFooter />

            </div>
        )
    }

}