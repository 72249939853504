export const URL = "http://localhost:9000";

export const firebaseConfig = {
  apiKey: "AIzaSyDbcpX-HFszV3XsWipWQyyk1LJLtloVaLY",
  authDomain: "pgp-customization.firebaseapp.com",
  projectId: "pgp-customization",
  storageBucket: "pgp-customization.appspot.com",
  messagingSenderId: "1034660640684",
  appId: "1:1034660640684:web:fdd448f95260a901ad2c09"
};

export const emailJSID = "user_zSpGyo2HFY6AbyvrEXgi5";
export const serviceID = "service_dlqp3t3";
export const templateID = "template_ziofrva";