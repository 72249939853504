import React from 'react';
import NavMenu from '../nav-menu/nav-menu';
import PageFooter from '../page-footer/page-footer';

import VideoView from '../video-view/video-view';

import '../main-view/main-view.scss';
import "./services-subview.scss";

export default class ServicesSubview extends React.Component {

    render() {

        return(
            <div className="services-subview">
                <NavMenu />
                <h1 style={{textAlign: "center"}}>Parking<span style={{color: "#F15B2A"}}> Garage </span>Pictures</h1>
                <VideoView videoID={this.props.service.videoID} title={this.props.page} description={this.props.service.description}/>
                <PageFooter />
            </div>
        )
    }

}