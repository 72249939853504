import React from 'react';
import NavMenu from '../nav-menu/nav-menu';
import PageFooter from '../page-footer/page-footer';
import VideoView from '../video-view/video-view';
import firebase from 'firebase';
import 'firebase/firestore';

import '../main-view/main-view.scss';
import './portfolio-view.scss';

export default class PortfolioView extends React.Component {
    constructor() {
        super();
        this.state = {
            portfolioVideos: []
        }
    }

    componentDidMount() {
        this.getPosts();
    }

    getPosts() {
        var postRef = firebase.firestore().collection('Portfolio');
        let portfolioPosts = [];
        postRef.orderBy("date", "desc").get().then((querySnapshot) => {
            querySnapshot.forEach((post) => {
                let newPost = post.data();
                portfolioPosts.push(newPost);
            })
            this.setState({portfolioVideos: portfolioPosts});
        }).catch(err => console.log(err));
    }

    render() {
        return (
            <div className="portfolio-view">
                <NavMenu />
                <div>
                    <h1 className="mx-auto" style={{textAlign: 'center'}}>Portfolio</h1>
                    <p className="body-text mx-auto" style={{width: '80%', textAlign: 'center'}}>
                        Over the years, we've done quite a bit. We've got a great team that has helped us on our different projects and are really proud of the work we've done. Here are some of the ones we've enjoyed the most:
                    </p>
                    <div>
                        {this.state.portfolioVideos.map((video) => {
                        return <div className="portfolio-piece"> 
                            <VideoView 
                                key={video.date}
                                title={video.title} 
                                videoID={video.videoID} 
                                description={video.description}
                            />
                        </div>
                        })}
                    </div>
                </div>
                <PageFooter />
            </div>
        )
    }
}