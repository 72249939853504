import React from 'react';
import NavMenu from '../nav-menu/nav-menu';
import PageFooter from '../page-footer/page-footer';

import header from '../../assets/about-us-header.jpg';
import Ryan from '../../assets/ryan-hero-0-Enhanced-Animated.gif';
import Joel from '../../assets/Joel-hero-0-Enhanced-Animated.gif';
import Team from '../../assets/team-pic.JPG';

import '../main-view/main-view.scss';
import "./about-view.scss";

export default class AboutView extends React.Component {

    render() {
        return(
            <div className="about-view">
                <NavMenu />
                <img 
                    src={header} 
                    className="header-img"
                    alt="Header with Ryan and Joel"
                />
                <h1 className="header-text" style={{textAlign: "center"}}>Parking<span style={{color: "#F15B2A"}}>Garage</span>Pictures</h1>
                <p className="mx-auto">
                    {this.props.page.description}
                </p>
                <div className="bios">
                    <div className="bio-column">
                        <h2>Ryan</h2>
                        <img 
                            src={Ryan} 
                            width="50%"
                            height="auto"
                            alt="Portrait of Ryan"
                            className="bio-image"
                        />
                        <p className="bio-text">
                            {this.props.page.ryanBio}
                        </p>
                    </div>
                    <div className="bio-column">
                        <h2>Joel</h2>
                        <img 
                            src={Joel} 
                            width="50%"
                            height="auto"
                            className="bio-image"
                            alt="Portrait of Joel"
                        />
                        <p className="bio-text">
                            {this.props.page.joelBio}
                        </p>
                    </div>
                </div>
                <h1 style={{textAlign: "center"}} className="mx-auto">The Team</h1>
                <div className="team">
                    <img 
                        src={Team} 
                        className="team-img"
                        alt="On set with PGP"
                    />
                    <p style={{flex: "2", margin: "40px"}}>
                        {this.props.page.teamDescription}
                    </p>
                </div>
                <PageFooter />
            </div>
        )
    }

}