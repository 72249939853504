import React from 'react';
import NavMenu from '../nav-menu/nav-menu';
import PageFooter from '../page-footer/page-footer';
import VideoView from '../video-view/video-view';
import vfxImage from '../../assets/VFX.png';
import motionGraphicsImage from '../../assets/motionGraphics.jpg';
import videoEditingImage from '../../assets/videoEditing.png';
import postProductionImg from '../../assets/postProd.png';

import '../main-view/main-view.scss';
import "./services-view.scss";

export default class ServicesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            description: "",
            vfxText: "",
            vfxImage: "",
            moGraphText: "",
            moGraphImage: "",
            vidEditText: "",
            vidEditImage: "",
            postText: "",
            postImage: "",
            servicesVideoID: "",
        }
    }

    getText() {
        //API call for info here.
        this.setState({
            description: "Parking Garage Pictures is a full post production house with full service production capability. Whether you need VFX, Motion Graphics, CGI, Video Editing, or just getting across the finish line, we've done it.",
            vfxText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dignissim arcu eros, et sollicitudin nisi pulvinar in. Suspendisse luctus magna et lorem bibendum aliquam. Quisque eget sem sed ipsum accumsan vulputate. Quisque vulputate odio sed mauris rutrum fermentum. Nulla purus arcu, aliquet eu posuere ac, euismod et est. Vivamus ut leo sed tortor eleifend dignissim.",
            moGraphText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dignissim arcu eros, et sollicitudin nisi pulvinar in. Suspendisse luctus magna et lorem bibendum aliquam. Quisque eget sem sed ipsum accumsan vulputate. Quisque vulputate odio sed mauris rutrum fermentum. Nulla purus arcu, aliquet eu posuere ac, euismod et est. Vivamus ut leo sed tortor eleifend dignissim.",
            vidEditText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dignissim arcu eros, et sollicitudin nisi pulvinar in. Suspendisse luctus magna et lorem bibendum aliquam. Quisque eget sem sed ipsum accumsan vulputate. Quisque vulputate odio sed mauris rutrum fermentum. Nulla purus arcu, aliquet eu posuere ac, euismod et est. Vivamus ut leo sed tortor eleifend dignissim.",
            postText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dignissim arcu eros, et sollicitudin nisi pulvinar in. Suspendisse luctus magna et lorem bibendum aliquam. Quisque eget sem sed ipsum accumsan vulputate. Quisque vulputate odio sed mauris rutrum fermentum. Nulla purus arcu, aliquet eu posuere ac, euismod et est. Vivamus ut leo sed tortor eleifend dignissim.",
            servicesVideoID: "326421288",
            vfxImage: "https://via.placeholder.com/400x300",
            moGraphImage: "https://via.placeholder.com/400x300",
            vidEditImage: "https://via.placeholder.com/400x300",
            postImage: "https://via.placeholder.com/400x300"
        })
    }

    componentDidMount() {
        this.getText();
    }

    render() {

        return(
            <div className="services-view">
                <NavMenu />
                <h1 className="header-text" style={{textAlign: "center", marginTop: "25px"}}>Parking<span style={{color: "#F15B2A"}}>Garage</span>Pictures</h1>
                <h2 style={{textAlign: "center"}}>Services</h2>
                <VideoView videoID={this.props.page.videoID} description={this.props.page.description}/>
                <h2 style={{marginTop: "75px", textAlign: "center"}}>our services include:</h2>
                <div className="subservice">
                    <img src={vfxImage} className="subservice-img" alt="VFX" />
                    <div className="article">
                        <h1 style={{textAlign: "center", margin: "25px"}}>VFX</h1>
                        <p className="mx-auto body-text rightish">
                            {this.props.vfx.description}
                        </p>
                        <a className="mx-auto btn-PGP" href="/vfx">
                            See our VFX Reel
                        </a>
                    </div>
                </div>
                <div className="subservice">
                    <div className="article">
                        <h1 style={{textAlign: "center", margin: "25px"}}>Motion Graphics</h1>
                        <p className="mx-auto body-text leftish">
                            {this.props.motionGraphics.description}
                        </p>
                        <a className="mx-auto btn-PGP" href="/motion-graphics">
                            See our Motion Graphics Reel
                        </a>
                    </div>
                    <img src={motionGraphicsImage} className="subservice-img" alt="Motion Graphics"/>
                </div>
                <div className="subservice">
                    <img src={videoEditingImage} className="subservice-img" alt="Video Editing"/>
                    <div className="article">
                        <h1 style={{textAlign: "center", margin: "25px"}}>Video Editing</h1>
                        <p className="mx-auto body-text rightish">
                            {this.props.videoEditing.description}
                        </p>
                        <a className="mx-auto btn-PGP" href="/video-editing">See our Video Editing Reel</a>
                    </div>
                </div>
                <div className="subservice">
                    <div className="article">
                        <h1 style={{textAlign: "center", margin: "25px"}}>Post Production</h1>
                        <p className="mx-auto body-text leftish">
                            {this.props.postProduction.description}
                        </p>
                        <a className="mx-auto btn-PGP" href="/fix-it-in-post">See our Post Production Reel</a>
                    </div>
                    <img src={postProductionImg} className="subservice-img" alt="Post Production"/>
                </div>
                <PageFooter />
            </div>
        )
    }

}