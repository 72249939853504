import './App.css';
import { firebaseConfig } from './utilities.jsx';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import MainView from './components/main-view/main-view';
import AboutView from './components/about-view/about-view';
import DevelopmentView from './components/development-view/development-view';
import ServicesView from './components/services-view/services-view';
import ServicesSubview from './components/services-subview/services-subview';
import QuoteView from './components/quote-view/quote-view';
import LoginView from './components/login-view/login-view';

import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AdminView from './components/admin-view/admin-view';
import PortfolioView from './components/portfolio-view/portfolio-view';
import React from 'react';


class App extends React.Component {
  constructor() {
    super()
    this.state = {
      home: "",
      about: "",
      services: "",
      vfx: "",
      videoEditing: "",
      motionGraphics: "",
      postProduction: "",
      portfolio: "",
      blogPosts: [],
    };
    if (!firebase.apps.length) {firebase.initializeApp(firebaseConfig)}


  }

  componentDidMount() {
    this.loadPageData();
    this.loadPortfolio();
    this.loadBlog();
  }

  loadPageData() {
    firebase.firestore().collection("PageData").get()
    .then((querySnapshot) => {
    querySnapshot.forEach((doc) => this.filterData(doc));
    })
  }

  loadPortfolio() {
    let portfolio = [];
    firebase.firestore().collection("Portfolio").get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) =>{
        portfolio.push(doc.data());
      })
    }).then(() => {this.setState({portfolio: portfolio})})
  }

  loadBlog() {
    let blogPosts = [];
    firebase.firestore().collection("BlogPosts").get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        blogPosts.push(doc.data());
      })
    }).catch(err => console.log(err))
    .then(() => {this.setState({blogPosts: blogPosts})})
    .catch(err => console.log(err))
  }

  filterData(doc) {
    switch (doc.id) {
      case 'home':
        this.setState({home: doc.data()});
        break;
      case 'about':
        this.setState({about: doc.data()});
        break;
      case 'services':
        this.setState({services: doc.data()});
        break;
      case 'vfx':
        this.setState({vfx: doc.data()});
        break;
      case 'videoEditing':
        this.setState({videoEditing: doc.data()});
        break;
      case 'motionGraphics':
        this.setState({motionGraphics: doc.data()});
        break;
      case 'postProduction':
        this.setState({postProduction: doc.data()});
        break;
      default:
        console.log(doc.id + " does not match.")
    }
  }

  render() {
    return (
        <Router>
          <Route exact path="/" render={() => <MainView page={this.state.home}/>} />

          <Route exact path="/about-us" render={() => <AboutView page={this.state.about}/>} />

          <Route exact path="/in-development" render={() => <DevelopmentView />} />

          <Route 
            exact path="/services" 
            render={() => <ServicesView 
              page={this.state.services}
              vfx={this.state.vfx}
              videoEditing={this.state.videoEditing}
              motionGraphics={this.state.motionGraphics}
              postProduction={this.state.postProduction}
            />} 
          />

          <Route exact path="/vfx" render={() => <ServicesSubview page="VFX" service={this.state.vfx}/>} />
          
          <Route exact path="/video-editing" render={() => <ServicesSubview page="Video Editing" service={this.state.videoEditing}/>} />
          
          <Route exact path="/motion-graphics" render={() => <ServicesSubview page="Motion Graphics" service={this.state.motionGraphics}/>} />
          
          <Route exact path="/fix-it-in-post" render={() => <ServicesSubview page="Post Production" service={this.state.postProduction}/>} />

          <Route exact path="/quote" render={() => <QuoteView />} />

          <Route exact path="/portfolio" render={() => <PortfolioView />} />

          <Route exact path="/admin/login" render={() => <LoginView />} />

          <Route exact path="/admin" render={() => <AdminView
              home={this.state.home}
              about={this.state.about}
              services={this.state.services}
              vfx={this.state.vfx}
              videoEditing={this.state.videoEditing}
              motionGraphics={this.state.motionGraphics}
              postProduction={this.state.postProduction}
              portfolio={this.state.portfolio}
              blogPosts={this.state.blogPosts}
          />} />
        </Router>
  )};
}

export default App;
