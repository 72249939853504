import React from 'react';
import NavMenu from '../nav-menu/nav-menu';
import BackgroundVideo from '../background-video/background-video';
import fullLogo from './fullLogo.png';
import sideCam from '../../assets/sideCam.JPG'
import firebase from 'firebase';

import './main-view.scss';
import PageFooter from '../page-footer/page-footer';

export default class MainView extends React.Component {
    componentDidMount() {
        firebase.auth().signOut();
    }

    render() {
        
        return (
            <div className="MainView">
                <NavMenu />
                <BackgroundVideo videoID={this.props.page.videoID} title="Show Reel"/>
                <div className="logo-card">
                    <img src={fullLogo} alt="" className="logo mx-auto" />
                    <h1 className="logo-text mx-auto"> PARKING<span style={{color: "#F15B2A"}}>GARAGE</span>PICTURES</h1>
                    <p className="subtitle">
                        {this.props.page.slogan}
                    </p>
                </div>
                <div className="summary">
                    <img src={sideCam} className="side-image"  alt="Camera Operator on set"/>
                    <div className="summary-text">
                        <h1 className="mx-auto header-text" style={{color: "white"}}> PARKING<span style={{color: "#F15B2A"}}>GARAGE</span>PICTURES</h1>
                        <p className="body-text">
                            {this.props.page.description}
                        </p>
                        <p className="body-text">
                            Check out our services:
                        </p>
                        <div className="btn-group">
                            <a className="btn-PGP mx-auto" href="/vfx">VFX</a>
                            <a className="btn-PGP mx-auto" href="/motion-graphics">Motion Graphics</a>
                            <a className="btn-PGP mx-auto" href="/video-editing">Video Editing</a>
                            <a className="btn-PGP mx-auto" href="/fix-it-in-post">Post Production</a>
                            <a className="btn-PGP mx-auto" href="/quote">Get a Quote</a>
                        </div>
                    </div>
                </div>
                <PageFooter/>
            </div>
        )
    }
}