import React from 'react';

import '../main-view/main-view.scss';
import "./blog-post.scss";

export default class BlogPost extends React.Component {



    render() {
        const { title, description, images } = this.props;
        
        let imgRender = images.map(image => {
            if (!image) return null;
            else return <img
                key={image}
                src={image} 
                className="blog-image mx-auto" 
                alt=""
                />
        });

        return (
            <div className="blog-post mx-auto">
                <h2 className="mx-auto blog-title">{title}</h2>
                <p className="mx-auto blog-text">{description}</p>
                <div className="image-list">
                    { imgRender }
                </div>
            </div>
        )
    }
}