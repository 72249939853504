import React from 'react';

import '../main-view/main-view.scss';
import "./video-view.scss";

export default class VideoView extends React.Component {

    render() {
        if (!this.props.videoID) {
            return (<div>Problem loading video</div>)
        }
        
        let address = "https://player.vimeo.com/video/" + this.props.videoID + "?color=F15B2A&title=0&byline=0&portrait=0";
        let title = this.props.title ||"";
        let description = this.props.description || "";

        return(
            <div className="video-view">
                <h2 className="mx-auto" style={{width: "100%", textAlign: "center", margin: "25px"}}>{title}</h2>
                <div style={{padding:"56.25% 0 0 0", position:"relative"}}>
                    <iframe 
                        src={address} //"https://player.vimeo.com/video/326642924?color=F15B2A&title=0&byline=0&portrait=0" 
                        style={{position:"absolute", top:0, left:0, width:"100%", height: "100%"}} 
                        frameBorder="0" 
                        allow="autoplay; fullscreen; picture-in-picture" 
                        allowFullScreen
                        title={title}
                    ></iframe>
                </div>
                <p className="video-description">
                    {description}
                </p>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
        )
    }

}