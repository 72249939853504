import React from 'react';
import NavMenu from '../nav-menu/nav-menu';
import PageFooter from '../page-footer/page-footer';
import BlogPost from '../blog-post/blog-post';
import firebase from 'firebase';

import '../main-view/main-view.scss';
import "./development-view.scss";

export default class DevelopmentView extends React.Component {
    constructor() {
        super()
        this.state = {
            blogPosts: []
        }
    }

    loadPosts() {
        let blogPosts = [];
        firebase.firestore().collection("BlogPosts").get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) =>{
            blogPosts.push(doc.data());
          })
        }).then(() => {this.setState({blogPosts: blogPosts})})
      }

    componentDidMount() {
        this.loadPosts();
    }

    render() {
        return(
            <div className="development-view">
                <NavMenu />
                <h1 className="mx-auto" style={{textAlign: 'center', marginTop: "50px"}}>In Development</h1>
                <p className="mx-auto" style={{textAlign: 'center', color: 'white'}}>If the earth is still spinning, you can bet we're working on something. Here's some of our latest projects:</p>
                {this.state.blogPosts.map((blog) =>
                    <BlogPost
                        key={blog.title}
                        title={blog.title}
                        description={blog.description}
                        images={blog.images}
                    />
                )}
                <PageFooter />
            </div>
        )
    }

}