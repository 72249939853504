import React from 'react';

import '../main-view/main-view.scss';
import "./background-video.scss";

export default class BackgroundVideo extends React.Component {

    render() {
        let { videoID, title } = this.props;
        let address = "https://player.vimeo.com/video/" + videoID + "?title=0&byline=0&portrait=0&amp;background=1";
        
        return(
            <div>
                <div style={{
                        padding:"56.25% 0 0 0",
                        position:"relative",
                        opacity:".2"
                    }}
                    >
                    <iframe 
                        src={address} 
                        style={{
                            position:"absolute",
                            top:"0",
                            left:"10%",
                            width:"80%",
                            height:"80%"}} 
                        frameBorder="0" 
                        allow="autoplay; fullscreen; picture-in-picture" 
                        title={title}
                    ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
        )
    }

}